/* eslint-disable */
import * as types from './graphql.js';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation TriggerComparison($original: ComparedInput!, $revised: ComparedInput!) {\n    compareFiles(input: { original: $original, revised: $revised }) {\n      success\n    }\n  }\n": types.TriggerComparisonDocument,
    "\n  subscription comparisonCreatedSubscription(\n    $originalFileRecordId: ID!\n    $revisedFileRecordId: ID!\n    $format: String\n  ) {\n    comparisonCreated(\n      originalFileRecordId: $originalFileRecordId\n      revisedFileRecordId: $revisedFileRecordId\n      format: $format\n    ) {\n      originalFileRecord {\n        id\n      }\n      revisedFileRecord {\n        id\n      }\n      mode\n      format\n      content\n      comments {\n        id\n        anchorElement\n        replies {\n          anchorElement\n        }\n        ...ComparisonComments_CommentFragment\n        ...ComparisonContent_CommentFragment\n      }\n      errors\n    }\n  }\n": types.ComparisonCreatedSubscriptionDocument,
    "\n  fragment Comparison_ButtonLink_FileContentFragment on FileRecord {\n    id\n  }\n": types.Comparison_ButtonLink_FileContentFragmentFragmentDoc,
    "\n  mutation GenerateChangesSummary($input: GenerateChangesSummaryInput!) {\n    generateComparisonChangesSummary(input: $input) {\n      changesSummary\n      errors\n    }\n  }\n": types.GenerateChangesSummaryDocument,
    "\n  fragment ComparisonComment_CommentFragment on Comment {\n    id\n    anchorElement\n    authorFullName\n    authorInitials\n    sanitizedHtml\n    createdAt\n    resolved\n  }\n": types.ComparisonComment_CommentFragmentFragmentDoc,
    "\n  fragment ComparisonCommentReply_CommentFragment on Comment {\n    id\n    authorFullName\n    authorInitials\n    sanitizedHtml\n    createdAt\n    resolved\n  }\n": types.ComparisonCommentReply_CommentFragmentFragmentDoc,
    "\n  fragment ComparisonComments_CommentFragment on Comment {\n    id\n    ...ComparisonComment_CommentFragment\n    replies {\n      id\n      ...ComparisonCommentReply_CommentFragment\n    }\n  }\n": types.ComparisonComments_CommentFragmentFragmentDoc,
    "\n  fragment ComparisonContent_CommentFragment on Comment {\n    id\n    anchorElement\n  }\n": types.ComparisonContent_CommentFragmentFragmentDoc,
    "\n  query GetFileRecord($fileRecordId: ID!) {\n    fileRecord(id: $fileRecordId) {\n      ...fileRecord\n    }\n  }\n": types.GetFileRecordDocument,
    "\n  fragment CloudStorageChip_FileLocationFragment on FileLocation {\n    ...FullFilePathText_FileLocationFragment\n\n    parentName\n    parentWebUrl\n    storedInService\n  }\n": types.CloudStorageChip_FileLocationFragmentFragmentDoc,
    "\n  fragment DriveList_DriveFragment on Drive {\n    id\n    cloudService\n    name\n  }\n": types.DriveList_DriveFragmentFragmentDoc,
    "\n  fragment EmailPreview_FileLocationFragment on FileLocation {\n    storedInService\n    parentName\n    email {\n      id\n      sentAt\n      snippet\n      subject\n\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n  }\n": types.EmailPreview_FileLocationFragmentFragmentDoc,
    "\n  query GetCloudStorageRecords($storageIntegrationId: ID, $itemId: String) {\n    cloudStorageItem(storageIntegrationId: $storageIntegrationId, itemId: $itemId) {\n      id\n      records(limit: 10) {\n        id\n        ...RecordCard_RecordFragment\n      }\n    }\n  }\n": types.GetCloudStorageRecordsDocument,
    "\n  fragment RecordCard_RecordFragment on Record {\n    id\n    parties {\n      id\n      name\n    }\n    ...RecordTitle_RecordFragment\n  }\n": types.RecordCard_RecordFragmentFragmentDoc,
    "\nfragment AttachmentList_FileLocationFragment on FileLocation {\n  id\n  name\n  fileContent {\n    id\n    fetchDownloadUrl\n  }\n}": types.AttachmentList_FileLocationFragmentFragmentDoc,
    "\n  fragment ContactAvatar_ContactFragment on Contact {\n    name\n    email\n  }\n": types.ContactAvatar_ContactFragmentFragmentDoc,
    "\n  fragment ListConversationDetail_ConversationFragment on Conversation {\n    id\n    ...ConversationHeader_ConversationFragment\n  }\n": types.ListConversationDetail_ConversationFragmentFragmentDoc,
    "\n  query GetConversationDetail($id: ID!) {\n    conversation(conversationId: $id) {\n      id\n      emails {\n        id\n        ...EmailMessage_EmailFragment\n        ...ConversationHeader_EmailFragment\n      }\n    }\n  }\n": types.GetConversationDetailDocument,
    "\n  fragment ConversationEmailView_ConversationFragment on Conversation {\n    id\n    ...ConversationList_ConversationFragment\n    ...ListConversationDetail_ConversationFragment\n  }\n": types.ConversationEmailView_ConversationFragmentFragmentDoc,
    "\n  query GetConversationList($recordId: ID!) {\n    record(id: $recordId) {\n      id\n      conversations {\n        id\n        ...ConversationEmailView_ConversationFragment\n      }\n    }\n  }\n": types.GetConversationListDocument,
    "\n  fragment ConversationHeader_EmailFragment on Email {\n    id\n    webUrl\n  }\n": types.ConversationHeader_EmailFragmentFragmentDoc,
    "\n  fragment ConversationHeader_ConversationFragment on Conversation {\n    subject\n  }\n": types.ConversationHeader_ConversationFragmentFragmentDoc,
    "\nfragment ConversationRow_ConversationFragment on Conversation {\n  id\n  subject\n  snippet\n  unread\n  lastMessageTimestamp\n\n  emails {\n    id\n    fileLocations {\n      id\n      ...AttachmentList_FileLocationFragment\n    }\n    from {\n      id\n      name\n      email\n      ...ContactAvatar_ContactFragment\n    }\n  }\n}\n": types.ConversationRow_ConversationFragmentFragmentDoc,
    "\n  fragment ConversationList_ConversationFragment on Conversation {\n    id\n    ...ConversationRow_ConversationFragment\n  }\n": types.ConversationList_ConversationFragmentFragmentDoc,
    "\n  query getEmailBody($emailId: ID!) {\n    email(emailId: $emailId) {\n      id\n      sanitizedBody\n      fileLocations {\n        id\n        ...AttachmentList_FileLocationFragment\n      }\n    }\n  }\n": types.GetEmailBodyDocument,
    "\n  fragment EmailMessage_EmailFragment on Email {\n    id\n    sentAt\n    snippet\n    from {\n      id\n      name\n      email\n      ...ContactAvatar_ContactFragment\n    }\n    to {\n      id\n      name\n      email\n    }\n  }\n": types.EmailMessage_EmailFragmentFragmentDoc,
    "\n  fragment CardTray_RecitalRecordFragment on Record {\n    ...RecordTitleLink_RecordFragment\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n  }\n": types.CardTray_RecitalRecordFragmentFragmentDoc,
    "\n  fragment EmailChip_FileLocationFragment on FileLocation {\n    ...EmailPreview_FileLocationFragment\n    storedInService\n    email {\n      id\n      webUrl\n\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n  }\n": types.EmailChip_FileLocationFragmentFragmentDoc,
    "\n  fragment FileLocationsDropdownItem_FileLocationFragment on FileLocation {\n    storedInService\n    parentName\n\n    ...FileLocationChip_FileLocationFragment\n    ...FileLocationsLink_FileLocationFragment\n  }\n": types.FileLocationsDropdownItem_FileLocationFragmentFragmentDoc,
    "\n  fragment FullFilePathText_FileLocationFragment on FileLocation {\n    name\n    parentName\n  }\n": types.FullFilePathText_FileLocationFragmentFragmentDoc,
    "\n  fragment FileLocationsLink_FileLocationFragment on FileLocation {\n    name\n    storedInService\n    parentWebUrl\n    email {\n      webUrl\n    }\n  }\n": types.FileLocationsLink_FileLocationFragmentFragmentDoc,
    "\n  fragment FileLocationsLink_FileContentFragment on FileRecord {\n    fileLocations {\n      id\n      storedInService\n      receivedAt\n      ...FileLocationChip_FileLocationFragment\n      ...FileLocationsDropdownItem_FileLocationFragment\n    }\n  }\n": types.FileLocationsLink_FileContentFragmentFragmentDoc,
    "\n  mutation CreateStorageIntegration($input: CreateStorageIntegrationInput!) {\n    createStorageIntegration(input: $input) {\n      storageIntegration {\n        id\n        scanStatus\n        cloudService\n        rootDirectory {\n          id\n          name\n        }\n      }\n      errors\n    }\n  }\n": types.CreateStorageIntegrationDocument,
    "\n  query GetDrives {\n    drives {\n      drives {\n        id\n        ...DriveList_DriveFragment\n        cloudStorageConnection {\n          id\n          cloudProvider\n        }\n      }\n      needsPermissions\n      errors\n    }\n  }\n": types.GetDrivesDocument,
    "\n  fragment ConnectionsItem_CloudStorageConnectionsFragment on CloudStorageConnection {\n    id\n    apiProvider\n    cloudProvider\n    connected\n    email\n    unifiedId\n  }\n": types.ConnectionsItem_CloudStorageConnectionsFragmentFragmentDoc,
    "\n    mutation deleteCloudStorageConnection($cloudStorageConnectionId: ID!) {\n      deleteIntegrationsCloudStorageConnection(\n        input: { cloudStorageConnectionId: $cloudStorageConnectionId }\n      ) {\n        errors\n      }\n    }\n  ": types.DeleteCloudStorageConnectionDocument,
    "\n  query GetCloudStorageConnections {\n    cloudStorageConnections {\n      id\n      apiProvider\n      ...ConnectionsItem_CloudStorageConnectionsFragment\n    }\n  }\n": types.GetCloudStorageConnectionsDocument,
    "\n    mutation CreateNewCloudStorageConnection($unifiedId: String!, $cloudProvider: String!) {\n      createIntegrationsCloudStorageConnection(\n        input: { unifiedId: $unifiedId, cloudProvider: $cloudProvider }\n      ) {\n        cloudStorageConnection {\n          ...ConnectionsItem_CloudStorageConnectionsFragment\n        }\n        errors\n      }\n    }\n  ": types.CreateNewCloudStorageConnectionDocument,
    "\n  mutation SetRecordNote($recordId: ID!, $note: String!) {\n    setRecordNote(input: { recordId: $recordId, note: $note }) {\n      record {\n        id\n        note\n      }\n      errors\n    }\n  }\n": types.SetRecordNoteDocument,
    "\n  mutation DeleteRecords($recordsIds: [ID!]!) {\n    deleteRecords(input: { recordsIds: $recordsIds }) {\n      ids\n      errors\n    }\n  }\n": types.DeleteRecordsDocument,
    "\n  mutation MergeRecords($fromId: [ID!]!, $toId: ID!) {\n    mergeRecords(input: { fromId: $fromId, toId: $toId }) {\n      id\n    }\n  }\n": types.MergeRecordsDocument,
    "\n  fragment ModalMergeRecords_RecordFragment on Record {\n    id\n    parties {\n      name\n    }\n    ...RecordTitle_RecordFragment\n  }\n": types.ModalMergeRecords_RecordFragmentFragmentDoc,
    "\n  fragment PartyChips_PartyFragment on Party {\n    id\n    name\n    ...PartyLink_PartyFragment\n    ...PartyNameChip_PartyFragment\n  }\n": types.PartyChips_PartyFragmentFragmentDoc,
    "\n  fragment PartyLink_PartyFragment on Party {\n    id\n    name\n  }\n": types.PartyLink_PartyFragmentFragmentDoc,
    "\n  fragment PartyNameChip_PartyFragment on Party {\n    id\n    name\n    ...PartyLink_PartyFragment\n  }\n": types.PartyNameChip_PartyFragmentFragmentDoc,
    "\n  fragment ChildRecord_RecordFragment on Record {\n    id\n    effectiveDate\n    executed\n    ...RecordTitleLink_RecordFragment\n  }\n": types.ChildRecord_RecordFragmentFragmentDoc,
    "\n  fragment ChildRecords_RecordFragment on Record {\n    id\n    effectiveDate\n    ...ChildRecord_RecordFragment\n  }\n": types.ChildRecords_RecordFragmentFragmentDoc,
    "\n  fragment RecordInformation_RecordFragment on Record {\n    id\n    executed\n    effectiveDate\n    missingParentAgreementTitle\n    ...RecordTitleLink_RecordFragment\n\n    parentRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n\n    childRecords {\n      ...ChildRecords_RecordFragment\n    }\n\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n      }\n    }\n  }\n": types.RecordInformation_RecordFragmentFragmentDoc,
    "\n  fragment RecordTitle_RecordFragment on Record {\n    title\n    latestFileRecord {\n      id\n      fileLocations {\n        id\n        name\n        storedInService\n        originatesFromThisUser\n        receivedAt\n      }\n    }\n  }\n": types.RecordTitle_RecordFragmentFragmentDoc,
    "\n  fragment RecordTitleAndSubtitle_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n    parties {\n      ...PartyChips_PartyFragment\n    }\n  }\n": types.RecordTitleAndSubtitle_RecordFragmentFragmentDoc,
    "\n  fragment RecordTitleLink_RecordFragment on Record {\n    id\n    executed\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n  }\n": types.RecordTitleLink_RecordFragmentFragmentDoc,
    "\n  fragment RecordVersions_CountBadge_RecordFragment on Record {\n    fileRecordCount\n  }\n": types.RecordVersions_CountBadge_RecordFragmentFragmentDoc,
    "\n  fragment RecordSelectorOption_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n\n    id\n    starred\n    parties {\n      name\n    }\n  }\n": types.RecordSelectorOption_RecordFragmentFragmentDoc,
    "\n  fragment RecordSelector_RecordFragment on Record {\n          id\n          title\n          lastActivityTime\n          starred\n          parties {\n            id\n            name\n          }\n          ...RecordSelectorOption_RecordFragment\n\n          # needed for determining the best file name\n          latestFileRecord {\n            id\n            fileLocations {\n              id\n              name\n              receivedAt\n            }\n          }\n  }\n": types.RecordSelector_RecordFragmentFragmentDoc,
    "\n  query GetRecordsForSelector($first: Int, $after: String, $filterText: String) {\n    records(first: $first, after: $after, filterText: $filterText) {\n      edges {\n        node {\n          ...RecordSelector_RecordFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetRecordsForSelectorDocument,
    "\n  fragment ExecutedListItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...ParentChildListItem_RecordFragment\n    effectiveDate\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n        ...FileLocationChip_FileLocationFragment\n      }\n    }\n  }\n": types.ExecutedListItem_RecordFragmentFragmentDoc,
    "\n  fragment InNegotiationListItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n    executed\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n        ...FileLocationChip_FileLocationFragment\n      }\n    }\n  }\n": types.InNegotiationListItem_RecordFragmentFragmentDoc,
    "\n  fragment ParentChildListItem_RecordFragment on Record {\n    parentRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n    childRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n  }\n": types.ParentChildListItem_RecordFragmentFragmentDoc,
    "\n  fragment partialRecord on Record {\n    id\n    lastActivityTime\n    starred\n    isContract\n    executed\n    ...ExecutedListItem_RecordFragment @include(if: $fetchExecuted)\n    ...InNegotiationListItem_RecordFragment @skip(if: $fetchExecuted)\n    parties {\n      id\n      name\n    }\n    fileRecords {\n      id\n      fileLocations {\n        ...fileLocation\n      }\n    }\n    # needed for determining the best file name\n    latestFileRecord {\n      id\n      fileLocations {\n        id\n        name\n        receivedAt\n      }\n    }\n  }\n": types.PartialRecordFragmentDoc,
    "\n  query GetRecordsList(\n    $first: Int\n    $after: String\n    $filterText: String\n    $isContract: Boolean\n    $filterStatus: RecordStatusFilterEnum\n    $fetchExecuted: Boolean!\n  ) {\n    records(\n      first: $first\n      after: $after\n      filterText: $filterText\n      isContract: $isContract\n      filterStatus: $filterStatus\n    ) {\n      edges {\n        node {\n          ...partialRecord\n          lastActivityTime\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetRecordsListDocument,
    "\n  subscription PartialRecordChanged($fetchExecuted: Boolean!) {\n    recordChanged {\n      ...partialRecord\n    }\n  }\n": types.PartialRecordChangedDocument,
    "\n  subscription RecordCreated($fetchExecuted: Boolean!) {\n    recordCreated {\n      ...partialRecord\n    }\n  }\n": types.RecordCreatedDocument,
    "\n    subscription RecordsListRecordDeleted {\n      recordDeleted {\n        id\n      }\n    }\n  ": types.RecordsListRecordDeletedDocument,
    "\n  fragment SidebarRecordItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n\n    parties {\n      id\n      name\n    }\n  }\n": types.SidebarRecordItem_RecordFragmentFragmentDoc,
    "\n  fragment ClauseResult_SimilarClauseWithMetadataFragment on SimilarClause {\n    similarityScore\n    clause {\n      id\n      text\n      category\n\n      ...ResultHeader_ClauseFragment\n      ...SaveClauseButton_ClauseFragment\n    }\n  }\n": types.ClauseResult_SimilarClauseWithMetadataFragmentFragmentDoc,
    "\n  query GetPartiesByPartialName($partialName: String) {\n    parties(sortBy: NAME, order: ASC, partialName: $partialName, first: 10){\n      nodes {\n        name\n        id\n      }\n    }\n  }\n": types.GetPartiesByPartialNameDocument,
    "\n  fragment ResultHeader_ClauseFragment on Clause {\n    fileRecordCount\n    latestFileRecord {\n      ...fileRecord\n      record {\n        id\n        ...RecordTitleLink_RecordFragment\n      }\n    }\n  }\n": types.ResultHeader_ClauseFragmentFragmentDoc,
    "\n  query GetSimilarClausesWithFilters(\n    $similarToText: String!\n    $parties: [String!]\n    $categories: [String!]\n    $latestVersion: Boolean\n    $excludeExactMatch: Boolean\n    $savedOnly: Boolean\n  ) {\n    filteredSimilarClauses(\n      similarToText: $similarToText\n      parties: $parties\n      categories: $categories\n      latestVersion: $latestVersion\n      excludeExactMatch: $excludeExactMatch\n      savedOnly: $savedOnly\n    ) {\n      filterMetadata {\n        numResults\n        categories\n        parties {\n          id\n          name\n        }\n      }\n\n      similarClauses {\n        clause {\n          id\n        }\n        ...ClauseResult_SimilarClauseWithMetadataFragment\n      }\n    }\n  }\n": types.GetSimilarClausesWithFiltersDocument,
    "\n  fragment SaveClauseButton_ClauseFragment on Clause {\n    id\n    saved\n    updateable\n  }\n": types.SaveClauseButton_ClauseFragmentFragmentDoc,
    "\n    mutation UpdateClause($clauseId: ID!, $saved: Boolean!) {\n      updateClause(input: { clauseId: $clauseId, saved: $saved }) {\n        clause {\n          ...SaveClauseButton_ClauseFragment\n        }\n      }\n    }\n  ": types.UpdateClauseDocument,
    "\n  fragment FileLocationChip_FileLocationFragment on FileLocation {\n    storedInService\n\n    ...EmailChip_FileLocationFragment\n    ...CloudStorageChip_FileLocationFragment\n  }\n": types.FileLocationChip_FileLocationFragmentFragmentDoc,
    "\n  query GetCloudStorageItem(\n    $cloudService: String\n    $driveId: String\n    $cloudStorageConnectionId: ID\n    $storageIntegrationId: ID\n    $itemId: String\n  ) {\n    cloudStorageItem(\n      cloudService: $cloudService\n      driveId: $driveId\n      cloudStorageConnectionId: $cloudStorageConnectionId\n      storageIntegrationId: $storageIntegrationId\n      itemId: $itemId\n    ) {\n      id\n      itemType\n      name\n      children {\n        id\n        name\n        itemType\n      }\n    }\n  }\n": types.GetCloudStorageItemDocument,
    "\n  mutation StarRecord($starred: Boolean!, $recordId: ID!) {\n    starRecord(input: { starred: $starred, recordId: $recordId }) {\n      starred\n    }\n  }\n": types.StarRecordDocument,
    "\n  query GetStarredRecords {\n    records(starred: true) {\n      edges {\n        node {\n          id\n          ...SidebarRecordItem_RecordFragment\n        }\n      }\n    }\n  }\n": types.GetStarredRecordsDocument,
    "\n  query ListConnectedDirectories {\n    storageIntegrations {\n      id\n      scanStatus\n      cloudService\n      rootDirectory {\n        id\n        name\n      }\n    }\n  }\n": types.ListConnectedDirectoriesDocument,
    "\n  fragment fileLocation on FileLocation {\n    id\n    name\n    parentName\n    receivedAt\n    originatesFromThisUser\n    storedInService\n    parentWebUrl\n    email {\n      id\n      sentAt\n      subject\n      snippet\n      webUrl\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n    creator {\n      id\n      name\n      email\n    }\n  }\n": types.FileLocationFragmentDoc,
    "\n  fragment fileRecord on FileRecord {\n    id\n    scanStatus\n    receivedAt\n    automaticGroupingCompletedAt\n    clauseIndexingCompletedAt\n    record {\n      id\n      title\n      parties {\n        id\n        name\n      }\n      # needed for determining the best file name\n      latestFileRecord {\n        id\n        fileLocations {\n          id\n          name\n          receivedAt\n        }\n      }\n      fileRecordCount\n    }\n    fetchDownloadUrl\n    fileLocations {\n      ...fileLocation\n    }\n  }\n": types.FileRecordFragmentDoc,
    "\n  fragment fullFileRecord on FileRecord {\n    id\n    scanStatus\n    record {\n      id\n      title\n      parties {\n        id\n        name\n      }\n      fileRecordCount\n      # needed for determining the best file name\n      latestFileRecord {\n        id\n        fileLocations {\n          id\n          name\n          receivedAt\n        }\n      }\n    }\n    fetchDownloadUrl\n    fileLocations {\n      ...fileLocation\n    }\n  }\n": types.FullFileRecordFragmentDoc,
    "\n  fragment fullRecord on Record {\n    id\n    ...RecordInformation_RecordFragment\n    starred\n    isContract\n    title\n    note\n    executed\n    effectiveDate\n    fileRecordCount\n    detectedClauses {\n      category\n      text\n    }\n    executedFileUrl\n    parties {\n      id\n      name\n    }\n    fileRecords {\n      ...fileRecord\n    }\n    conversations {\n      id\n      sentAt # used for chronological ordering\n    }\n    latestFileRecord {\n      id\n      bestComparison {\n        baseFileRecord {\n          ...fileRecord\n        }\n        comparedFileRecord {\n          ...fileRecord\n        }\n      }\n      fileLocations {\n        id\n        name\n        receivedAt\n      }\n    }\n  }\n": types.FullRecordFragmentDoc,
    "\n  mutation AddParties($recordId: ID!, $partiesNames: [String!]!) {\n    addParties(input: { recordId: $recordId, partiesNames: $partiesNames }) {\n      id # record ID\n      parties {\n        id\n        name\n      }\n    }\n  }\n": types.AddPartiesDocument,
    "\n  mutation CreateFileRecord($uploadedFile: Upload!, $referenceDocument: Boolean) {\n    createFileRecord(\n      input: { uploadedFile: $uploadedFile, referenceDocument: $referenceDocument }\n    ) {\n      fileRecord {\n        ...fileRecord\n      }\n      errors\n    }\n  }\n": types.CreateFileRecordDocument,
    "\n  mutation CreateFromFileRecords(\n    $fileRecordsIds: [ID!]!\n    $title: String!\n    $partyNames: [String!]!\n  ) {\n    createFromFileRecords(\n      input: { fileRecordsIds: $fileRecordsIds, title: $title, partyNames: $partyNames }\n    ) {\n      record {\n        id\n        title\n        starred\n        parties {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CreateFromFileRecordsDocument,
    "\n  mutation DeleteFileContent($fileRecordId: ID!) {\n    deleteFileContent(input: { fileRecordId: $fileRecordId }) {\n      id\n    }\n  }\n": types.DeleteFileContentDocument,
    "\n  mutation DeleteUser($userId: ID!) {\n    deleteUser(input: { userId: $userId }) {\n      id\n    }\n  }\n": types.DeleteUserDocument,
    "\n  mutation DiscardParties($partyIds: [ID!]!) {\n    discardParties(input: { partyIds: $partyIds }) {\n      errors\n    }\n  }\n": types.DiscardPartiesDocument,
    "\n  mutation MoveFileRecords($recordId: ID!, $fileRecordsIds: [ID!]!) {\n    moveFileRecords(input: { recordId: $recordId, fileRecordsIds: $fileRecordsIds }) {\n      record {\n        id\n      }\n      fileRecords {\n        id\n      }\n    }\n  }\n": types.MoveFileRecordsDocument,
    "\n  mutation RemoveParties($recordId: ID!, $partyIds: [ID!]!) {\n    removeParties(input: { recordId: $recordId, partyIds: $partyIds }) {\n      id # Record ID\n      parties {\n        id\n        name\n      }\n    }\n  }\n": types.RemovePartiesDocument,
    "\n  mutation SaveFileRecordToCloudStorage(\n    $storageIntegrationId: ID!\n    $fileRecordId: ID!\n    $directoryId: String!\n    $fileName: String!\n  ) {\n    saveFileRecordToCloudStorage(\n      input: {\n        storageIntegrationId: $storageIntegrationId\n        fileRecordId: $fileRecordId\n        directoryId: $directoryId\n        fileName: $fileName\n      }\n    ) {\n      fileLocation {\n        ...fileLocation\n      }\n    }\n  }\n": types.SaveFileRecordToCloudStorageDocument,
    "\n  mutation SetOnboardingSteps($steps: [UserOnboardingStep!]!) {\n    setOnboardingSteps(input: { steps: $steps }) {\n      user {\n        id\n      }\n    }\n  }\n": types.SetOnboardingStepsDocument,
    "\n  mutation UndeleteFileContent($fileRecordId: ID!) {\n    undeleteFileContent(input: { fileRecordId: $fileRecordId }) {\n      fileRecord {\n        ...fullFileRecord\n      }\n    }\n  }\n": types.UndeleteFileContentDocument,
    "\n  mutation UndeleteRecords($recordIds: [ID!]!) {\n    undeleteRecords(input: { recordIds: $recordIds }) {\n      records {\n        ...fullRecord\n      }\n    }\n  }\n": types.UndeleteRecordsDocument,
    "\n  mutation UpdateParties($parties: [PartyInput!]!) {\n    updateParties(input: { parties: $parties }) {\n      parties {\n        id\n        name\n      }\n      errors\n    }\n  }\n": types.UpdatePartiesDocument,
    "\n  mutation UpdateParty($partyId: ID!, $name: String!) {\n    setPartyName(input: { partyId: $partyId, name: $name }) {\n      party {\n        id\n        name\n      }\n      errors\n    }\n  }\n": types.UpdatePartyDocument,
    "\n  mutation SetRecordTitle($recordId: ID!, $title: String!) {\n    setRecordTitle(input: { recordId: $recordId, title: $title }) {\n      id\n      title\n      parties {\n        name\n        id\n      }\n    }\n  }\n": types.SetRecordTitleDocument,
    "\n  query GetComparisonSuggestion($revisedFileId: ID!) {\n    fileRecord(id: $revisedFileId) {\n      id\n      bestComparison {\n        baseFileRecord {\n          ...fileRecord\n        }\n      }\n    }\n  }\n": types.GetComparisonSuggestionDocument,
    "\n  query getEmailsWithFileContentsFromProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            name\n            id\n            fileRecord {\n              ...fileRecord\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetEmailsWithFileContentsFromProviderIdDocument,
    "\n  query GetExpiringToken {\n    expiringToken\n  }\n": types.GetExpiringTokenDocument,
    "\n  query GetParties($after: String, $order: Order!, $sortBy: PartiesSortBy!) {\n    parties(first: 25, after: $after, order: $order, sortBy: $sortBy) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetPartiesDocument,
    "\n  query GetRecentFilesForCompareSuggestions($after: String, $isContract: Boolean) {\n    files(first: 10, after: $after, isContract: $isContract) {\n      edges {\n        node {\n          ...fileRecord\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetRecentFilesForCompareSuggestionsDocument,
    "\n  query GetRecord($recordId: ID!) {\n    record(id: $recordId) {\n      ...fullRecord\n    }\n  }\n": types.GetRecordDocument,
    "\n  query GetRecordFileRecords($recordId: ID!) {\n    record(id: $recordId) {\n      id\n      fileRecords {\n        ...fileRecord\n      }\n    }\n  }\n": types.GetRecordFileRecordsDocument,
    "\n  query GetReferenceDocuments {\n    files(referenceDocument: true) {\n      edges {\n        node {\n          ...fileRecord\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetReferenceDocumentsDocument,
    "\n  subscription comparisonToFileCreatedSubscription(\n    $originalFileRecordId: ID!\n    $revisedFileRecordId: ID!\n    $format: String\n  ) {\n    comparisonCreated(\n      originalFileRecordId: $originalFileRecordId\n      revisedFileRecordId: $revisedFileRecordId\n      format: $format\n    ) {\n      originalFileRecord {\n        id\n      }\n      revisedFileRecord {\n        id\n      }\n      format\n      errors\n    }\n  }\n": types.ComparisonToFileCreatedSubscriptionDocument,
    "\n  subscription fileContentChangedSubscription {\n    fileContentChanged {\n      bestComparison {\n        baseFileRecord {\n          id\n        }\n        comparedFileRecord {\n          id\n        }\n      }\n      ...fileRecord\n    }\n  }\n": types.FileContentChangedSubscriptionDocument,
    "\n  subscription FileContentCreated {\n    fileContentCreated {\n      bestComparison {\n        baseFileRecord {\n          id\n        }\n        comparedFileRecord {\n          id\n        }\n      }\n      ...fileRecord\n    }\n  }\n": types.FileContentCreatedDocument,
    "\n  subscription FileContentDeleted {\n    fileContentDeleted {\n      id\n    }\n  }\n": types.FileContentDeletedDocument,
    "\n  subscription RecordChanged {\n    recordChanged {\n      ...fullRecord\n    }\n  }\n": types.RecordChangedDocument,
    "\n  query GetComparisonFromEmailProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            id\n            fileRecord {\n              id\n              record {\n                id\n              }\n            }\n            bestComparison {\n              baseFileRecord {\n                id\n              }\n              comparedFileRecord {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetComparisonFromEmailProviderIdDocument,
    "\n  query GetRecordsFromProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            id\n            fileRecord {\n              id\n              record {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetRecordsFromProviderIdDocument,
    "\n    subscription RecordPageRecordDeleted {\n      recordDeleted {\n        id\n      }\n    }\n  ": types.RecordPageRecordDeletedDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation TriggerComparison($original: ComparedInput!, $revised: ComparedInput!) {\n    compareFiles(input: { original: $original, revised: $revised }) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation TriggerComparison($original: ComparedInput!, $revised: ComparedInput!) {\n    compareFiles(input: { original: $original, revised: $revised }) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription comparisonCreatedSubscription(\n    $originalFileRecordId: ID!\n    $revisedFileRecordId: ID!\n    $format: String\n  ) {\n    comparisonCreated(\n      originalFileRecordId: $originalFileRecordId\n      revisedFileRecordId: $revisedFileRecordId\n      format: $format\n    ) {\n      originalFileRecord {\n        id\n      }\n      revisedFileRecord {\n        id\n      }\n      mode\n      format\n      content\n      comments {\n        id\n        anchorElement\n        replies {\n          anchorElement\n        }\n        ...ComparisonComments_CommentFragment\n        ...ComparisonContent_CommentFragment\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  subscription comparisonCreatedSubscription(\n    $originalFileRecordId: ID!\n    $revisedFileRecordId: ID!\n    $format: String\n  ) {\n    comparisonCreated(\n      originalFileRecordId: $originalFileRecordId\n      revisedFileRecordId: $revisedFileRecordId\n      format: $format\n    ) {\n      originalFileRecord {\n        id\n      }\n      revisedFileRecord {\n        id\n      }\n      mode\n      format\n      content\n      comments {\n        id\n        anchorElement\n        replies {\n          anchorElement\n        }\n        ...ComparisonComments_CommentFragment\n        ...ComparisonContent_CommentFragment\n      }\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Comparison_ButtonLink_FileContentFragment on FileRecord {\n    id\n  }\n"): (typeof documents)["\n  fragment Comparison_ButtonLink_FileContentFragment on FileRecord {\n    id\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateChangesSummary($input: GenerateChangesSummaryInput!) {\n    generateComparisonChangesSummary(input: $input) {\n      changesSummary\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation GenerateChangesSummary($input: GenerateChangesSummaryInput!) {\n    generateComparisonChangesSummary(input: $input) {\n      changesSummary\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ComparisonComment_CommentFragment on Comment {\n    id\n    anchorElement\n    authorFullName\n    authorInitials\n    sanitizedHtml\n    createdAt\n    resolved\n  }\n"): (typeof documents)["\n  fragment ComparisonComment_CommentFragment on Comment {\n    id\n    anchorElement\n    authorFullName\n    authorInitials\n    sanitizedHtml\n    createdAt\n    resolved\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ComparisonCommentReply_CommentFragment on Comment {\n    id\n    authorFullName\n    authorInitials\n    sanitizedHtml\n    createdAt\n    resolved\n  }\n"): (typeof documents)["\n  fragment ComparisonCommentReply_CommentFragment on Comment {\n    id\n    authorFullName\n    authorInitials\n    sanitizedHtml\n    createdAt\n    resolved\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ComparisonComments_CommentFragment on Comment {\n    id\n    ...ComparisonComment_CommentFragment\n    replies {\n      id\n      ...ComparisonCommentReply_CommentFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ComparisonComments_CommentFragment on Comment {\n    id\n    ...ComparisonComment_CommentFragment\n    replies {\n      id\n      ...ComparisonCommentReply_CommentFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ComparisonContent_CommentFragment on Comment {\n    id\n    anchorElement\n  }\n"): (typeof documents)["\n  fragment ComparisonContent_CommentFragment on Comment {\n    id\n    anchorElement\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFileRecord($fileRecordId: ID!) {\n    fileRecord(id: $fileRecordId) {\n      ...fileRecord\n    }\n  }\n"): (typeof documents)["\n  query GetFileRecord($fileRecordId: ID!) {\n    fileRecord(id: $fileRecordId) {\n      ...fileRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CloudStorageChip_FileLocationFragment on FileLocation {\n    ...FullFilePathText_FileLocationFragment\n\n    parentName\n    parentWebUrl\n    storedInService\n  }\n"): (typeof documents)["\n  fragment CloudStorageChip_FileLocationFragment on FileLocation {\n    ...FullFilePathText_FileLocationFragment\n\n    parentName\n    parentWebUrl\n    storedInService\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DriveList_DriveFragment on Drive {\n    id\n    cloudService\n    name\n  }\n"): (typeof documents)["\n  fragment DriveList_DriveFragment on Drive {\n    id\n    cloudService\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment EmailPreview_FileLocationFragment on FileLocation {\n    storedInService\n    parentName\n    email {\n      id\n      sentAt\n      snippet\n      subject\n\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment EmailPreview_FileLocationFragment on FileLocation {\n    storedInService\n    parentName\n    email {\n      id\n      sentAt\n      snippet\n      subject\n\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCloudStorageRecords($storageIntegrationId: ID, $itemId: String) {\n    cloudStorageItem(storageIntegrationId: $storageIntegrationId, itemId: $itemId) {\n      id\n      records(limit: 10) {\n        id\n        ...RecordCard_RecordFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCloudStorageRecords($storageIntegrationId: ID, $itemId: String) {\n    cloudStorageItem(storageIntegrationId: $storageIntegrationId, itemId: $itemId) {\n      id\n      records(limit: 10) {\n        id\n        ...RecordCard_RecordFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordCard_RecordFragment on Record {\n    id\n    parties {\n      id\n      name\n    }\n    ...RecordTitle_RecordFragment\n  }\n"): (typeof documents)["\n  fragment RecordCard_RecordFragment on Record {\n    id\n    parties {\n      id\n      name\n    }\n    ...RecordTitle_RecordFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment AttachmentList_FileLocationFragment on FileLocation {\n  id\n  name\n  fileContent {\n    id\n    fetchDownloadUrl\n  }\n}"): (typeof documents)["\nfragment AttachmentList_FileLocationFragment on FileLocation {\n  id\n  name\n  fileContent {\n    id\n    fetchDownloadUrl\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ContactAvatar_ContactFragment on Contact {\n    name\n    email\n  }\n"): (typeof documents)["\n  fragment ContactAvatar_ContactFragment on Contact {\n    name\n    email\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ListConversationDetail_ConversationFragment on Conversation {\n    id\n    ...ConversationHeader_ConversationFragment\n  }\n"): (typeof documents)["\n  fragment ListConversationDetail_ConversationFragment on Conversation {\n    id\n    ...ConversationHeader_ConversationFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetConversationDetail($id: ID!) {\n    conversation(conversationId: $id) {\n      id\n      emails {\n        id\n        ...EmailMessage_EmailFragment\n        ...ConversationHeader_EmailFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetConversationDetail($id: ID!) {\n    conversation(conversationId: $id) {\n      id\n      emails {\n        id\n        ...EmailMessage_EmailFragment\n        ...ConversationHeader_EmailFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ConversationEmailView_ConversationFragment on Conversation {\n    id\n    ...ConversationList_ConversationFragment\n    ...ListConversationDetail_ConversationFragment\n  }\n"): (typeof documents)["\n  fragment ConversationEmailView_ConversationFragment on Conversation {\n    id\n    ...ConversationList_ConversationFragment\n    ...ListConversationDetail_ConversationFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetConversationList($recordId: ID!) {\n    record(id: $recordId) {\n      id\n      conversations {\n        id\n        ...ConversationEmailView_ConversationFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetConversationList($recordId: ID!) {\n    record(id: $recordId) {\n      id\n      conversations {\n        id\n        ...ConversationEmailView_ConversationFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ConversationHeader_EmailFragment on Email {\n    id\n    webUrl\n  }\n"): (typeof documents)["\n  fragment ConversationHeader_EmailFragment on Email {\n    id\n    webUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ConversationHeader_ConversationFragment on Conversation {\n    subject\n  }\n"): (typeof documents)["\n  fragment ConversationHeader_ConversationFragment on Conversation {\n    subject\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment ConversationRow_ConversationFragment on Conversation {\n  id\n  subject\n  snippet\n  unread\n  lastMessageTimestamp\n\n  emails {\n    id\n    fileLocations {\n      id\n      ...AttachmentList_FileLocationFragment\n    }\n    from {\n      id\n      name\n      email\n      ...ContactAvatar_ContactFragment\n    }\n  }\n}\n"): (typeof documents)["\nfragment ConversationRow_ConversationFragment on Conversation {\n  id\n  subject\n  snippet\n  unread\n  lastMessageTimestamp\n\n  emails {\n    id\n    fileLocations {\n      id\n      ...AttachmentList_FileLocationFragment\n    }\n    from {\n      id\n      name\n      email\n      ...ContactAvatar_ContactFragment\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ConversationList_ConversationFragment on Conversation {\n    id\n    ...ConversationRow_ConversationFragment\n  }\n"): (typeof documents)["\n  fragment ConversationList_ConversationFragment on Conversation {\n    id\n    ...ConversationRow_ConversationFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getEmailBody($emailId: ID!) {\n    email(emailId: $emailId) {\n      id\n      sanitizedBody\n      fileLocations {\n        id\n        ...AttachmentList_FileLocationFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query getEmailBody($emailId: ID!) {\n    email(emailId: $emailId) {\n      id\n      sanitizedBody\n      fileLocations {\n        id\n        ...AttachmentList_FileLocationFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment EmailMessage_EmailFragment on Email {\n    id\n    sentAt\n    snippet\n    from {\n      id\n      name\n      email\n      ...ContactAvatar_ContactFragment\n    }\n    to {\n      id\n      name\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment EmailMessage_EmailFragment on Email {\n    id\n    sentAt\n    snippet\n    from {\n      id\n      name\n      email\n      ...ContactAvatar_ContactFragment\n    }\n    to {\n      id\n      name\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CardTray_RecitalRecordFragment on Record {\n    ...RecordTitleLink_RecordFragment\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n  }\n"): (typeof documents)["\n  fragment CardTray_RecitalRecordFragment on Record {\n    ...RecordTitleLink_RecordFragment\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment EmailChip_FileLocationFragment on FileLocation {\n    ...EmailPreview_FileLocationFragment\n    storedInService\n    email {\n      id\n      webUrl\n\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment EmailChip_FileLocationFragment on FileLocation {\n    ...EmailPreview_FileLocationFragment\n    storedInService\n    email {\n      id\n      webUrl\n\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FileLocationsDropdownItem_FileLocationFragment on FileLocation {\n    storedInService\n    parentName\n\n    ...FileLocationChip_FileLocationFragment\n    ...FileLocationsLink_FileLocationFragment\n  }\n"): (typeof documents)["\n  fragment FileLocationsDropdownItem_FileLocationFragment on FileLocation {\n    storedInService\n    parentName\n\n    ...FileLocationChip_FileLocationFragment\n    ...FileLocationsLink_FileLocationFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FullFilePathText_FileLocationFragment on FileLocation {\n    name\n    parentName\n  }\n"): (typeof documents)["\n  fragment FullFilePathText_FileLocationFragment on FileLocation {\n    name\n    parentName\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FileLocationsLink_FileLocationFragment on FileLocation {\n    name\n    storedInService\n    parentWebUrl\n    email {\n      webUrl\n    }\n  }\n"): (typeof documents)["\n  fragment FileLocationsLink_FileLocationFragment on FileLocation {\n    name\n    storedInService\n    parentWebUrl\n    email {\n      webUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FileLocationsLink_FileContentFragment on FileRecord {\n    fileLocations {\n      id\n      storedInService\n      receivedAt\n      ...FileLocationChip_FileLocationFragment\n      ...FileLocationsDropdownItem_FileLocationFragment\n    }\n  }\n"): (typeof documents)["\n  fragment FileLocationsLink_FileContentFragment on FileRecord {\n    fileLocations {\n      id\n      storedInService\n      receivedAt\n      ...FileLocationChip_FileLocationFragment\n      ...FileLocationsDropdownItem_FileLocationFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateStorageIntegration($input: CreateStorageIntegrationInput!) {\n    createStorageIntegration(input: $input) {\n      storageIntegration {\n        id\n        scanStatus\n        cloudService\n        rootDirectory {\n          id\n          name\n        }\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation CreateStorageIntegration($input: CreateStorageIntegrationInput!) {\n    createStorageIntegration(input: $input) {\n      storageIntegration {\n        id\n        scanStatus\n        cloudService\n        rootDirectory {\n          id\n          name\n        }\n      }\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDrives {\n    drives {\n      drives {\n        id\n        ...DriveList_DriveFragment\n        cloudStorageConnection {\n          id\n          cloudProvider\n        }\n      }\n      needsPermissions\n      errors\n    }\n  }\n"): (typeof documents)["\n  query GetDrives {\n    drives {\n      drives {\n        id\n        ...DriveList_DriveFragment\n        cloudStorageConnection {\n          id\n          cloudProvider\n        }\n      }\n      needsPermissions\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ConnectionsItem_CloudStorageConnectionsFragment on CloudStorageConnection {\n    id\n    apiProvider\n    cloudProvider\n    connected\n    email\n    unifiedId\n  }\n"): (typeof documents)["\n  fragment ConnectionsItem_CloudStorageConnectionsFragment on CloudStorageConnection {\n    id\n    apiProvider\n    cloudProvider\n    connected\n    email\n    unifiedId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation deleteCloudStorageConnection($cloudStorageConnectionId: ID!) {\n      deleteIntegrationsCloudStorageConnection(\n        input: { cloudStorageConnectionId: $cloudStorageConnectionId }\n      ) {\n        errors\n      }\n    }\n  "): (typeof documents)["\n    mutation deleteCloudStorageConnection($cloudStorageConnectionId: ID!) {\n      deleteIntegrationsCloudStorageConnection(\n        input: { cloudStorageConnectionId: $cloudStorageConnectionId }\n      ) {\n        errors\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCloudStorageConnections {\n    cloudStorageConnections {\n      id\n      apiProvider\n      ...ConnectionsItem_CloudStorageConnectionsFragment\n    }\n  }\n"): (typeof documents)["\n  query GetCloudStorageConnections {\n    cloudStorageConnections {\n      id\n      apiProvider\n      ...ConnectionsItem_CloudStorageConnectionsFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateNewCloudStorageConnection($unifiedId: String!, $cloudProvider: String!) {\n      createIntegrationsCloudStorageConnection(\n        input: { unifiedId: $unifiedId, cloudProvider: $cloudProvider }\n      ) {\n        cloudStorageConnection {\n          ...ConnectionsItem_CloudStorageConnectionsFragment\n        }\n        errors\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateNewCloudStorageConnection($unifiedId: String!, $cloudProvider: String!) {\n      createIntegrationsCloudStorageConnection(\n        input: { unifiedId: $unifiedId, cloudProvider: $cloudProvider }\n      ) {\n        cloudStorageConnection {\n          ...ConnectionsItem_CloudStorageConnectionsFragment\n        }\n        errors\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetRecordNote($recordId: ID!, $note: String!) {\n    setRecordNote(input: { recordId: $recordId, note: $note }) {\n      record {\n        id\n        note\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation SetRecordNote($recordId: ID!, $note: String!) {\n    setRecordNote(input: { recordId: $recordId, note: $note }) {\n      record {\n        id\n        note\n      }\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteRecords($recordsIds: [ID!]!) {\n    deleteRecords(input: { recordsIds: $recordsIds }) {\n      ids\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteRecords($recordsIds: [ID!]!) {\n    deleteRecords(input: { recordsIds: $recordsIds }) {\n      ids\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MergeRecords($fromId: [ID!]!, $toId: ID!) {\n    mergeRecords(input: { fromId: $fromId, toId: $toId }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation MergeRecords($fromId: [ID!]!, $toId: ID!) {\n    mergeRecords(input: { fromId: $fromId, toId: $toId }) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ModalMergeRecords_RecordFragment on Record {\n    id\n    parties {\n      name\n    }\n    ...RecordTitle_RecordFragment\n  }\n"): (typeof documents)["\n  fragment ModalMergeRecords_RecordFragment on Record {\n    id\n    parties {\n      name\n    }\n    ...RecordTitle_RecordFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PartyChips_PartyFragment on Party {\n    id\n    name\n    ...PartyLink_PartyFragment\n    ...PartyNameChip_PartyFragment\n  }\n"): (typeof documents)["\n  fragment PartyChips_PartyFragment on Party {\n    id\n    name\n    ...PartyLink_PartyFragment\n    ...PartyNameChip_PartyFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PartyLink_PartyFragment on Party {\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment PartyLink_PartyFragment on Party {\n    id\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PartyNameChip_PartyFragment on Party {\n    id\n    name\n    ...PartyLink_PartyFragment\n  }\n"): (typeof documents)["\n  fragment PartyNameChip_PartyFragment on Party {\n    id\n    name\n    ...PartyLink_PartyFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ChildRecord_RecordFragment on Record {\n    id\n    effectiveDate\n    executed\n    ...RecordTitleLink_RecordFragment\n  }\n"): (typeof documents)["\n  fragment ChildRecord_RecordFragment on Record {\n    id\n    effectiveDate\n    executed\n    ...RecordTitleLink_RecordFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ChildRecords_RecordFragment on Record {\n    id\n    effectiveDate\n    ...ChildRecord_RecordFragment\n  }\n"): (typeof documents)["\n  fragment ChildRecords_RecordFragment on Record {\n    id\n    effectiveDate\n    ...ChildRecord_RecordFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordInformation_RecordFragment on Record {\n    id\n    executed\n    effectiveDate\n    missingParentAgreementTitle\n    ...RecordTitleLink_RecordFragment\n\n    parentRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n\n    childRecords {\n      ...ChildRecords_RecordFragment\n    }\n\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment RecordInformation_RecordFragment on Record {\n    id\n    executed\n    effectiveDate\n    missingParentAgreementTitle\n    ...RecordTitleLink_RecordFragment\n\n    parentRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n\n    childRecords {\n      ...ChildRecords_RecordFragment\n    }\n\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordTitle_RecordFragment on Record {\n    title\n    latestFileRecord {\n      id\n      fileLocations {\n        id\n        name\n        storedInService\n        originatesFromThisUser\n        receivedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment RecordTitle_RecordFragment on Record {\n    title\n    latestFileRecord {\n      id\n      fileLocations {\n        id\n        name\n        storedInService\n        originatesFromThisUser\n        receivedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordTitleAndSubtitle_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n    parties {\n      ...PartyChips_PartyFragment\n    }\n  }\n"): (typeof documents)["\n  fragment RecordTitleAndSubtitle_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n    parties {\n      ...PartyChips_PartyFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordTitleLink_RecordFragment on Record {\n    id\n    executed\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n  }\n"): (typeof documents)["\n  fragment RecordTitleLink_RecordFragment on Record {\n    id\n    executed\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordVersions_CountBadge_RecordFragment on Record {\n    fileRecordCount\n  }\n"): (typeof documents)["\n  fragment RecordVersions_CountBadge_RecordFragment on Record {\n    fileRecordCount\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordSelectorOption_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n\n    id\n    starred\n    parties {\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment RecordSelectorOption_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n\n    id\n    starred\n    parties {\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RecordSelector_RecordFragment on Record {\n          id\n          title\n          lastActivityTime\n          starred\n          parties {\n            id\n            name\n          }\n          ...RecordSelectorOption_RecordFragment\n\n          # needed for determining the best file name\n          latestFileRecord {\n            id\n            fileLocations {\n              id\n              name\n              receivedAt\n            }\n          }\n  }\n"): (typeof documents)["\n  fragment RecordSelector_RecordFragment on Record {\n          id\n          title\n          lastActivityTime\n          starred\n          parties {\n            id\n            name\n          }\n          ...RecordSelectorOption_RecordFragment\n\n          # needed for determining the best file name\n          latestFileRecord {\n            id\n            fileLocations {\n              id\n              name\n              receivedAt\n            }\n          }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecordsForSelector($first: Int, $after: String, $filterText: String) {\n    records(first: $first, after: $after, filterText: $filterText) {\n      edges {\n        node {\n          ...RecordSelector_RecordFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecordsForSelector($first: Int, $after: String, $filterText: String) {\n    records(first: $first, after: $after, filterText: $filterText) {\n      edges {\n        node {\n          ...RecordSelector_RecordFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ExecutedListItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...ParentChildListItem_RecordFragment\n    effectiveDate\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n        ...FileLocationChip_FileLocationFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ExecutedListItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...ParentChildListItem_RecordFragment\n    effectiveDate\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n        ...FileLocationChip_FileLocationFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment InNegotiationListItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n    executed\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n        ...FileLocationChip_FileLocationFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment InNegotiationListItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n    ...RecordVersions_CountBadge_RecordFragment\n    executed\n    parties {\n      id\n      ...PartyChips_PartyFragment\n    }\n    fileRecords {\n      id\n      fileLocations {\n        id\n        receivedAt\n        ...FileLocationChip_FileLocationFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ParentChildListItem_RecordFragment on Record {\n    parentRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n    childRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ParentChildListItem_RecordFragment on Record {\n    parentRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n    childRecords {\n      id\n      ...RecordTitleLink_RecordFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment partialRecord on Record {\n    id\n    lastActivityTime\n    starred\n    isContract\n    executed\n    ...ExecutedListItem_RecordFragment @include(if: $fetchExecuted)\n    ...InNegotiationListItem_RecordFragment @skip(if: $fetchExecuted)\n    parties {\n      id\n      name\n    }\n    fileRecords {\n      id\n      fileLocations {\n        ...fileLocation\n      }\n    }\n    # needed for determining the best file name\n    latestFileRecord {\n      id\n      fileLocations {\n        id\n        name\n        receivedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment partialRecord on Record {\n    id\n    lastActivityTime\n    starred\n    isContract\n    executed\n    ...ExecutedListItem_RecordFragment @include(if: $fetchExecuted)\n    ...InNegotiationListItem_RecordFragment @skip(if: $fetchExecuted)\n    parties {\n      id\n      name\n    }\n    fileRecords {\n      id\n      fileLocations {\n        ...fileLocation\n      }\n    }\n    # needed for determining the best file name\n    latestFileRecord {\n      id\n      fileLocations {\n        id\n        name\n        receivedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecordsList(\n    $first: Int\n    $after: String\n    $filterText: String\n    $isContract: Boolean\n    $filterStatus: RecordStatusFilterEnum\n    $fetchExecuted: Boolean!\n  ) {\n    records(\n      first: $first\n      after: $after\n      filterText: $filterText\n      isContract: $isContract\n      filterStatus: $filterStatus\n    ) {\n      edges {\n        node {\n          ...partialRecord\n          lastActivityTime\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecordsList(\n    $first: Int\n    $after: String\n    $filterText: String\n    $isContract: Boolean\n    $filterStatus: RecordStatusFilterEnum\n    $fetchExecuted: Boolean!\n  ) {\n    records(\n      first: $first\n      after: $after\n      filterText: $filterText\n      isContract: $isContract\n      filterStatus: $filterStatus\n    ) {\n      edges {\n        node {\n          ...partialRecord\n          lastActivityTime\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription PartialRecordChanged($fetchExecuted: Boolean!) {\n    recordChanged {\n      ...partialRecord\n    }\n  }\n"): (typeof documents)["\n  subscription PartialRecordChanged($fetchExecuted: Boolean!) {\n    recordChanged {\n      ...partialRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription RecordCreated($fetchExecuted: Boolean!) {\n    recordCreated {\n      ...partialRecord\n    }\n  }\n"): (typeof documents)["\n  subscription RecordCreated($fetchExecuted: Boolean!) {\n    recordCreated {\n      ...partialRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    subscription RecordsListRecordDeleted {\n      recordDeleted {\n        id\n      }\n    }\n  "): (typeof documents)["\n    subscription RecordsListRecordDeleted {\n      recordDeleted {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SidebarRecordItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n\n    parties {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment SidebarRecordItem_RecordFragment on Record {\n    ...RecordTitle_RecordFragment\n\n    parties {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ClauseResult_SimilarClauseWithMetadataFragment on SimilarClause {\n    similarityScore\n    clause {\n      id\n      text\n      category\n\n      ...ResultHeader_ClauseFragment\n      ...SaveClauseButton_ClauseFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ClauseResult_SimilarClauseWithMetadataFragment on SimilarClause {\n    similarityScore\n    clause {\n      id\n      text\n      category\n\n      ...ResultHeader_ClauseFragment\n      ...SaveClauseButton_ClauseFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPartiesByPartialName($partialName: String) {\n    parties(sortBy: NAME, order: ASC, partialName: $partialName, first: 10){\n      nodes {\n        name\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPartiesByPartialName($partialName: String) {\n    parties(sortBy: NAME, order: ASC, partialName: $partialName, first: 10){\n      nodes {\n        name\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ResultHeader_ClauseFragment on Clause {\n    fileRecordCount\n    latestFileRecord {\n      ...fileRecord\n      record {\n        id\n        ...RecordTitleLink_RecordFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ResultHeader_ClauseFragment on Clause {\n    fileRecordCount\n    latestFileRecord {\n      ...fileRecord\n      record {\n        id\n        ...RecordTitleLink_RecordFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSimilarClausesWithFilters(\n    $similarToText: String!\n    $parties: [String!]\n    $categories: [String!]\n    $latestVersion: Boolean\n    $excludeExactMatch: Boolean\n    $savedOnly: Boolean\n  ) {\n    filteredSimilarClauses(\n      similarToText: $similarToText\n      parties: $parties\n      categories: $categories\n      latestVersion: $latestVersion\n      excludeExactMatch: $excludeExactMatch\n      savedOnly: $savedOnly\n    ) {\n      filterMetadata {\n        numResults\n        categories\n        parties {\n          id\n          name\n        }\n      }\n\n      similarClauses {\n        clause {\n          id\n        }\n        ...ClauseResult_SimilarClauseWithMetadataFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSimilarClausesWithFilters(\n    $similarToText: String!\n    $parties: [String!]\n    $categories: [String!]\n    $latestVersion: Boolean\n    $excludeExactMatch: Boolean\n    $savedOnly: Boolean\n  ) {\n    filteredSimilarClauses(\n      similarToText: $similarToText\n      parties: $parties\n      categories: $categories\n      latestVersion: $latestVersion\n      excludeExactMatch: $excludeExactMatch\n      savedOnly: $savedOnly\n    ) {\n      filterMetadata {\n        numResults\n        categories\n        parties {\n          id\n          name\n        }\n      }\n\n      similarClauses {\n        clause {\n          id\n        }\n        ...ClauseResult_SimilarClauseWithMetadataFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SaveClauseButton_ClauseFragment on Clause {\n    id\n    saved\n    updateable\n  }\n"): (typeof documents)["\n  fragment SaveClauseButton_ClauseFragment on Clause {\n    id\n    saved\n    updateable\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateClause($clauseId: ID!, $saved: Boolean!) {\n      updateClause(input: { clauseId: $clauseId, saved: $saved }) {\n        clause {\n          ...SaveClauseButton_ClauseFragment\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateClause($clauseId: ID!, $saved: Boolean!) {\n      updateClause(input: { clauseId: $clauseId, saved: $saved }) {\n        clause {\n          ...SaveClauseButton_ClauseFragment\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FileLocationChip_FileLocationFragment on FileLocation {\n    storedInService\n\n    ...EmailChip_FileLocationFragment\n    ...CloudStorageChip_FileLocationFragment\n  }\n"): (typeof documents)["\n  fragment FileLocationChip_FileLocationFragment on FileLocation {\n    storedInService\n\n    ...EmailChip_FileLocationFragment\n    ...CloudStorageChip_FileLocationFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCloudStorageItem(\n    $cloudService: String\n    $driveId: String\n    $cloudStorageConnectionId: ID\n    $storageIntegrationId: ID\n    $itemId: String\n  ) {\n    cloudStorageItem(\n      cloudService: $cloudService\n      driveId: $driveId\n      cloudStorageConnectionId: $cloudStorageConnectionId\n      storageIntegrationId: $storageIntegrationId\n      itemId: $itemId\n    ) {\n      id\n      itemType\n      name\n      children {\n        id\n        name\n        itemType\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCloudStorageItem(\n    $cloudService: String\n    $driveId: String\n    $cloudStorageConnectionId: ID\n    $storageIntegrationId: ID\n    $itemId: String\n  ) {\n    cloudStorageItem(\n      cloudService: $cloudService\n      driveId: $driveId\n      cloudStorageConnectionId: $cloudStorageConnectionId\n      storageIntegrationId: $storageIntegrationId\n      itemId: $itemId\n    ) {\n      id\n      itemType\n      name\n      children {\n        id\n        name\n        itemType\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation StarRecord($starred: Boolean!, $recordId: ID!) {\n    starRecord(input: { starred: $starred, recordId: $recordId }) {\n      starred\n    }\n  }\n"): (typeof documents)["\n  mutation StarRecord($starred: Boolean!, $recordId: ID!) {\n    starRecord(input: { starred: $starred, recordId: $recordId }) {\n      starred\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStarredRecords {\n    records(starred: true) {\n      edges {\n        node {\n          id\n          ...SidebarRecordItem_RecordFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStarredRecords {\n    records(starred: true) {\n      edges {\n        node {\n          id\n          ...SidebarRecordItem_RecordFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListConnectedDirectories {\n    storageIntegrations {\n      id\n      scanStatus\n      cloudService\n      rootDirectory {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListConnectedDirectories {\n    storageIntegrations {\n      id\n      scanStatus\n      cloudService\n      rootDirectory {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fileLocation on FileLocation {\n    id\n    name\n    parentName\n    receivedAt\n    originatesFromThisUser\n    storedInService\n    parentWebUrl\n    email {\n      id\n      sentAt\n      subject\n      snippet\n      webUrl\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n    creator {\n      id\n      name\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment fileLocation on FileLocation {\n    id\n    name\n    parentName\n    receivedAt\n    originatesFromThisUser\n    storedInService\n    parentWebUrl\n    email {\n      id\n      sentAt\n      subject\n      snippet\n      webUrl\n      from {\n        id\n        name\n        email\n      }\n      to {\n        id\n        name\n        email\n      }\n    }\n    creator {\n      id\n      name\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fileRecord on FileRecord {\n    id\n    scanStatus\n    receivedAt\n    automaticGroupingCompletedAt\n    clauseIndexingCompletedAt\n    record {\n      id\n      title\n      parties {\n        id\n        name\n      }\n      # needed for determining the best file name\n      latestFileRecord {\n        id\n        fileLocations {\n          id\n          name\n          receivedAt\n        }\n      }\n      fileRecordCount\n    }\n    fetchDownloadUrl\n    fileLocations {\n      ...fileLocation\n    }\n  }\n"): (typeof documents)["\n  fragment fileRecord on FileRecord {\n    id\n    scanStatus\n    receivedAt\n    automaticGroupingCompletedAt\n    clauseIndexingCompletedAt\n    record {\n      id\n      title\n      parties {\n        id\n        name\n      }\n      # needed for determining the best file name\n      latestFileRecord {\n        id\n        fileLocations {\n          id\n          name\n          receivedAt\n        }\n      }\n      fileRecordCount\n    }\n    fetchDownloadUrl\n    fileLocations {\n      ...fileLocation\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fullFileRecord on FileRecord {\n    id\n    scanStatus\n    record {\n      id\n      title\n      parties {\n        id\n        name\n      }\n      fileRecordCount\n      # needed for determining the best file name\n      latestFileRecord {\n        id\n        fileLocations {\n          id\n          name\n          receivedAt\n        }\n      }\n    }\n    fetchDownloadUrl\n    fileLocations {\n      ...fileLocation\n    }\n  }\n"): (typeof documents)["\n  fragment fullFileRecord on FileRecord {\n    id\n    scanStatus\n    record {\n      id\n      title\n      parties {\n        id\n        name\n      }\n      fileRecordCount\n      # needed for determining the best file name\n      latestFileRecord {\n        id\n        fileLocations {\n          id\n          name\n          receivedAt\n        }\n      }\n    }\n    fetchDownloadUrl\n    fileLocations {\n      ...fileLocation\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fullRecord on Record {\n    id\n    ...RecordInformation_RecordFragment\n    starred\n    isContract\n    title\n    note\n    executed\n    effectiveDate\n    fileRecordCount\n    detectedClauses {\n      category\n      text\n    }\n    executedFileUrl\n    parties {\n      id\n      name\n    }\n    fileRecords {\n      ...fileRecord\n    }\n    conversations {\n      id\n      sentAt # used for chronological ordering\n    }\n    latestFileRecord {\n      id\n      bestComparison {\n        baseFileRecord {\n          ...fileRecord\n        }\n        comparedFileRecord {\n          ...fileRecord\n        }\n      }\n      fileLocations {\n        id\n        name\n        receivedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment fullRecord on Record {\n    id\n    ...RecordInformation_RecordFragment\n    starred\n    isContract\n    title\n    note\n    executed\n    effectiveDate\n    fileRecordCount\n    detectedClauses {\n      category\n      text\n    }\n    executedFileUrl\n    parties {\n      id\n      name\n    }\n    fileRecords {\n      ...fileRecord\n    }\n    conversations {\n      id\n      sentAt # used for chronological ordering\n    }\n    latestFileRecord {\n      id\n      bestComparison {\n        baseFileRecord {\n          ...fileRecord\n        }\n        comparedFileRecord {\n          ...fileRecord\n        }\n      }\n      fileLocations {\n        id\n        name\n        receivedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddParties($recordId: ID!, $partiesNames: [String!]!) {\n    addParties(input: { recordId: $recordId, partiesNames: $partiesNames }) {\n      id # record ID\n      parties {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddParties($recordId: ID!, $partiesNames: [String!]!) {\n    addParties(input: { recordId: $recordId, partiesNames: $partiesNames }) {\n      id # record ID\n      parties {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateFileRecord($uploadedFile: Upload!, $referenceDocument: Boolean) {\n    createFileRecord(\n      input: { uploadedFile: $uploadedFile, referenceDocument: $referenceDocument }\n    ) {\n      fileRecord {\n        ...fileRecord\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFileRecord($uploadedFile: Upload!, $referenceDocument: Boolean) {\n    createFileRecord(\n      input: { uploadedFile: $uploadedFile, referenceDocument: $referenceDocument }\n    ) {\n      fileRecord {\n        ...fileRecord\n      }\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateFromFileRecords(\n    $fileRecordsIds: [ID!]!\n    $title: String!\n    $partyNames: [String!]!\n  ) {\n    createFromFileRecords(\n      input: { fileRecordsIds: $fileRecordsIds, title: $title, partyNames: $partyNames }\n    ) {\n      record {\n        id\n        title\n        starred\n        parties {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFromFileRecords(\n    $fileRecordsIds: [ID!]!\n    $title: String!\n    $partyNames: [String!]!\n  ) {\n    createFromFileRecords(\n      input: { fileRecordsIds: $fileRecordsIds, title: $title, partyNames: $partyNames }\n    ) {\n      record {\n        id\n        title\n        starred\n        parties {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteFileContent($fileRecordId: ID!) {\n    deleteFileContent(input: { fileRecordId: $fileRecordId }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteFileContent($fileRecordId: ID!) {\n    deleteFileContent(input: { fileRecordId: $fileRecordId }) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUser($userId: ID!) {\n    deleteUser(input: { userId: $userId }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUser($userId: ID!) {\n    deleteUser(input: { userId: $userId }) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DiscardParties($partyIds: [ID!]!) {\n    discardParties(input: { partyIds: $partyIds }) {\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation DiscardParties($partyIds: [ID!]!) {\n    discardParties(input: { partyIds: $partyIds }) {\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MoveFileRecords($recordId: ID!, $fileRecordsIds: [ID!]!) {\n    moveFileRecords(input: { recordId: $recordId, fileRecordsIds: $fileRecordsIds }) {\n      record {\n        id\n      }\n      fileRecords {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MoveFileRecords($recordId: ID!, $fileRecordsIds: [ID!]!) {\n    moveFileRecords(input: { recordId: $recordId, fileRecordsIds: $fileRecordsIds }) {\n      record {\n        id\n      }\n      fileRecords {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveParties($recordId: ID!, $partyIds: [ID!]!) {\n    removeParties(input: { recordId: $recordId, partyIds: $partyIds }) {\n      id # Record ID\n      parties {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveParties($recordId: ID!, $partyIds: [ID!]!) {\n    removeParties(input: { recordId: $recordId, partyIds: $partyIds }) {\n      id # Record ID\n      parties {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveFileRecordToCloudStorage(\n    $storageIntegrationId: ID!\n    $fileRecordId: ID!\n    $directoryId: String!\n    $fileName: String!\n  ) {\n    saveFileRecordToCloudStorage(\n      input: {\n        storageIntegrationId: $storageIntegrationId\n        fileRecordId: $fileRecordId\n        directoryId: $directoryId\n        fileName: $fileName\n      }\n    ) {\n      fileLocation {\n        ...fileLocation\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveFileRecordToCloudStorage(\n    $storageIntegrationId: ID!\n    $fileRecordId: ID!\n    $directoryId: String!\n    $fileName: String!\n  ) {\n    saveFileRecordToCloudStorage(\n      input: {\n        storageIntegrationId: $storageIntegrationId\n        fileRecordId: $fileRecordId\n        directoryId: $directoryId\n        fileName: $fileName\n      }\n    ) {\n      fileLocation {\n        ...fileLocation\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetOnboardingSteps($steps: [UserOnboardingStep!]!) {\n    setOnboardingSteps(input: { steps: $steps }) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetOnboardingSteps($steps: [UserOnboardingStep!]!) {\n    setOnboardingSteps(input: { steps: $steps }) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UndeleteFileContent($fileRecordId: ID!) {\n    undeleteFileContent(input: { fileRecordId: $fileRecordId }) {\n      fileRecord {\n        ...fullFileRecord\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UndeleteFileContent($fileRecordId: ID!) {\n    undeleteFileContent(input: { fileRecordId: $fileRecordId }) {\n      fileRecord {\n        ...fullFileRecord\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UndeleteRecords($recordIds: [ID!]!) {\n    undeleteRecords(input: { recordIds: $recordIds }) {\n      records {\n        ...fullRecord\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UndeleteRecords($recordIds: [ID!]!) {\n    undeleteRecords(input: { recordIds: $recordIds }) {\n      records {\n        ...fullRecord\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateParties($parties: [PartyInput!]!) {\n    updateParties(input: { parties: $parties }) {\n      parties {\n        id\n        name\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateParties($parties: [PartyInput!]!) {\n    updateParties(input: { parties: $parties }) {\n      parties {\n        id\n        name\n      }\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateParty($partyId: ID!, $name: String!) {\n    setPartyName(input: { partyId: $partyId, name: $name }) {\n      party {\n        id\n        name\n      }\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateParty($partyId: ID!, $name: String!) {\n    setPartyName(input: { partyId: $partyId, name: $name }) {\n      party {\n        id\n        name\n      }\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetRecordTitle($recordId: ID!, $title: String!) {\n    setRecordTitle(input: { recordId: $recordId, title: $title }) {\n      id\n      title\n      parties {\n        name\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetRecordTitle($recordId: ID!, $title: String!) {\n    setRecordTitle(input: { recordId: $recordId, title: $title }) {\n      id\n      title\n      parties {\n        name\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetComparisonSuggestion($revisedFileId: ID!) {\n    fileRecord(id: $revisedFileId) {\n      id\n      bestComparison {\n        baseFileRecord {\n          ...fileRecord\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetComparisonSuggestion($revisedFileId: ID!) {\n    fileRecord(id: $revisedFileId) {\n      id\n      bestComparison {\n        baseFileRecord {\n          ...fileRecord\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getEmailsWithFileContentsFromProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            name\n            id\n            fileRecord {\n              ...fileRecord\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getEmailsWithFileContentsFromProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            name\n            id\n            fileRecord {\n              ...fileRecord\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetExpiringToken {\n    expiringToken\n  }\n"): (typeof documents)["\n  query GetExpiringToken {\n    expiringToken\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetParties($after: String, $order: Order!, $sortBy: PartiesSortBy!) {\n    parties(first: 25, after: $after, order: $order, sortBy: $sortBy) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetParties($after: String, $order: Order!, $sortBy: PartiesSortBy!) {\n    parties(first: 25, after: $after, order: $order, sortBy: $sortBy) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecentFilesForCompareSuggestions($after: String, $isContract: Boolean) {\n    files(first: 10, after: $after, isContract: $isContract) {\n      edges {\n        node {\n          ...fileRecord\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecentFilesForCompareSuggestions($after: String, $isContract: Boolean) {\n    files(first: 10, after: $after, isContract: $isContract) {\n      edges {\n        node {\n          ...fileRecord\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecord($recordId: ID!) {\n    record(id: $recordId) {\n      ...fullRecord\n    }\n  }\n"): (typeof documents)["\n  query GetRecord($recordId: ID!) {\n    record(id: $recordId) {\n      ...fullRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecordFileRecords($recordId: ID!) {\n    record(id: $recordId) {\n      id\n      fileRecords {\n        ...fileRecord\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecordFileRecords($recordId: ID!) {\n    record(id: $recordId) {\n      id\n      fileRecords {\n        ...fileRecord\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetReferenceDocuments {\n    files(referenceDocument: true) {\n      edges {\n        node {\n          ...fileRecord\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetReferenceDocuments {\n    files(referenceDocument: true) {\n      edges {\n        node {\n          ...fileRecord\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription comparisonToFileCreatedSubscription(\n    $originalFileRecordId: ID!\n    $revisedFileRecordId: ID!\n    $format: String\n  ) {\n    comparisonCreated(\n      originalFileRecordId: $originalFileRecordId\n      revisedFileRecordId: $revisedFileRecordId\n      format: $format\n    ) {\n      originalFileRecord {\n        id\n      }\n      revisedFileRecord {\n        id\n      }\n      format\n      errors\n    }\n  }\n"): (typeof documents)["\n  subscription comparisonToFileCreatedSubscription(\n    $originalFileRecordId: ID!\n    $revisedFileRecordId: ID!\n    $format: String\n  ) {\n    comparisonCreated(\n      originalFileRecordId: $originalFileRecordId\n      revisedFileRecordId: $revisedFileRecordId\n      format: $format\n    ) {\n      originalFileRecord {\n        id\n      }\n      revisedFileRecord {\n        id\n      }\n      format\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription fileContentChangedSubscription {\n    fileContentChanged {\n      bestComparison {\n        baseFileRecord {\n          id\n        }\n        comparedFileRecord {\n          id\n        }\n      }\n      ...fileRecord\n    }\n  }\n"): (typeof documents)["\n  subscription fileContentChangedSubscription {\n    fileContentChanged {\n      bestComparison {\n        baseFileRecord {\n          id\n        }\n        comparedFileRecord {\n          id\n        }\n      }\n      ...fileRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription FileContentCreated {\n    fileContentCreated {\n      bestComparison {\n        baseFileRecord {\n          id\n        }\n        comparedFileRecord {\n          id\n        }\n      }\n      ...fileRecord\n    }\n  }\n"): (typeof documents)["\n  subscription FileContentCreated {\n    fileContentCreated {\n      bestComparison {\n        baseFileRecord {\n          id\n        }\n        comparedFileRecord {\n          id\n        }\n      }\n      ...fileRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription FileContentDeleted {\n    fileContentDeleted {\n      id\n    }\n  }\n"): (typeof documents)["\n  subscription FileContentDeleted {\n    fileContentDeleted {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription RecordChanged {\n    recordChanged {\n      ...fullRecord\n    }\n  }\n"): (typeof documents)["\n  subscription RecordChanged {\n    recordChanged {\n      ...fullRecord\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetComparisonFromEmailProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            id\n            fileRecord {\n              id\n              record {\n                id\n              }\n            }\n            bestComparison {\n              baseFileRecord {\n                id\n              }\n              comparedFileRecord {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetComparisonFromEmailProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            id\n            fileRecord {\n              id\n              record {\n                id\n              }\n            }\n            bestComparison {\n              baseFileRecord {\n                id\n              }\n              comparedFileRecord {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecordsFromProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            id\n            fileRecord {\n              id\n              record {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecordsFromProviderId($providerId: String!) {\n    emails(providerId: $providerId) {\n      edges {\n        node {\n          id\n          fileLocations {\n            id\n            fileRecord {\n              id\n              record {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    subscription RecordPageRecordDeleted {\n      recordDeleted {\n        id\n      }\n    }\n  "): (typeof documents)["\n    subscription RecordPageRecordDeleted {\n      recordDeleted {\n        id\n      }\n    }\n  "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;