import { captureMessage } from "@sentry/vue"
import axios from "~/lib/axios"
import { showAuthException } from "~/lib/componentUtils"
import { AlertType, showToast } from "~/lib/notify"
import { useUserInfoStore } from "~/stores/userInfo"

interface UserAuthenticationArgs {
  token: string
  tokenType: string
  pkceVerifier: string
  appType: string
}

interface UserAuthorizationArgs {
  token: string
  pkceVerifier: string
  appType: string
}

export const getUser = async (): Promise<void> => {
  const userInfoStore = useUserInfoStore()
  try {
    const response = await axios.get("/api/v1/user")
    userInfoStore.updateUser(response.data)
    // clear the getUserEndpointFailed if we've successfully gotten the user
    if (userInfoStore.getUserEndpointFailed) {
      userInfoStore.updateGetUserEndpointFailed(false)
    }
  } catch (error) {
    // if we've already tried to get the user and failed, don't try again
    if (userInfoStore.getUserEndpointFailed) {
      userInfoStore.updateGetUserEndpointFailed(false)
      return
    }

    userInfoStore.updateGetUserEndpointFailed(true)
    userInfoStore.updateUser(undefined)

    // a 401 Unauthorized indicates we're not logged in -- and we'll reflect this
    // by clearing the user state in our store; other errors need to be reported
    if (error.response?.status !== 401) {
      showAuthException(error)
    }
  }
}

export const endUserSession = async (): Promise<void> => {
  const userInfoStore = useUserInfoStore()
  try {
    await axios.get("/api/v1/user/logout")
    userInfoStore.updateUser(undefined)
  } catch (error) {
    captureMessage(`Couldn't log user out: ${error}`)
  }
}

// create or update the user in the recital backend (and get the latest user_info back)
export const createOrUpdateUser = async ({
  token,
  tokenType,
  pkceVerifier,
  appType,
}: UserAuthenticationArgs): Promise<void> => {
  const userInfoStore = useUserInfoStore()

  const response = await axios.post("/api/v1/user/create_or_update", {
    token,
    token_type: tokenType,
    pkce_verifier: pkceVerifier,
    office_addin: appType === "office-addin",
  })
  userInfoStore.updateUser(response.data)
}

export const authorize = async ({
  token,
  pkceVerifier,
  appType,
}: UserAuthorizationArgs): Promise<void> => {
  const userInfoStore = useUserInfoStore()

  try {
    const response = await axios.post("/api/v1/user/authorize", {
      token,
      pkce_verifier: pkceVerifier,
      office_addin: appType === "office-addin",
    })
    userInfoStore.updateUser(response.data)
  } catch (error) {
    if (error.response?.data?.error_type === "no_existing_or_new_google_refresh_token") {
      showToast(
        'We were not able to connect your Google account due to outdated Recital permissions in Google. Please revoke the old "Recital" access in your Google account settings and try again.',
        {
          type: AlertType.ERROR,
          action: {
            text: "Open Google Settings",
            onClick: () => {
              window.open("https://myaccount.google.com/connections", "_blank", "noopener")
            },
          },
        },
      )
      return
    }
    // bubble to generic error handler
    throw error
  }
}
