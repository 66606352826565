import { createNotifier, defineNotificationComponent } from "notiwind"
import type { NotificationSchema } from "notiwind/dist/types"
import { logUsage } from "~/lib/logUsage"

type CloseFunction = () => void

export { NotificationGroup } from "notiwind"

export interface ToastAction {
  onClick?: (e: MouseEvent, close: CloseFunction) => void
  text: string
  url?: string
}

export enum AlertType {
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

export interface NotificationPayload extends NotificationSchema {
  text: string
  title?: string
  type?: AlertType
  action?: ToastAction
  duration: number
}

interface NotificationOptions {
  title?: string
  type?: AlertType
  action?: ToastAction
  duration?: number
}

const notify = createNotifier<NotificationPayload>()
export const Notification = defineNotificationComponent<NotificationPayload>()

export const showToast = (message: string, options: NotificationOptions = {}): void => {
  // TODO: Most of the below logic can be centralized to the Toast action itself
  const { type = AlertType.SUCCESS, action, title } = options

  let { duration } = options
  if (type === AlertType.ERROR) {
    // We want errors to stick around long enough to be copied/screenshotted, but not so long that
    // they're permanent -- especially because some errors don't impact the user at all
    duration = 60_000
  } else if (options.action) {
    // In order to give users time to act on action toasts
    // time is set to 5 seconds, non-action ones are set to 3
    // See "Move from soft deletion to hard deletion" design doc for more info
    duration ||= 5000
  } else {
    duration ||= 3000
  }

  notify(
    {
      group: "toasts",
      text: message,
      action,
      type,
      duration,
      title,
    },
    duration,
  )

  if (type !== AlertType.SUCCESS && type !== AlertType.INFO) {
    // Always use event: 'error' because that's what the backend
    // uses to trigger displaying the error in Slack, that means it will
    // show the messages as the user saw an error even if it was a
    // different toast type
    logUsage({ event: "error", errorMessage: message })
  }
}

export default showToast
